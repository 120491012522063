import { Box } from '@chakra-ui/react';
import { FC, ReactNode, useEffect } from 'react';
import model from '../model';

const FullDropzone: FC<{ children: ReactNode }> = ({ children }) => {
  const { updateIsWindowDragActive } = model.useStoreMethods();

  useEffect(() => {
    const handleDragOver = (event: DragEvent) => {
      event.preventDefault();
      updateIsWindowDragActive(true);
    };

    const handleDragLeave = (event: DragEvent) => {
      // if the cursor is outside the window
      if (event.clientX === 0 && event.clientY === 0) {
        updateIsWindowDragActive(false);
      }
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
    };
  }, [updateIsWindowDragActive]);

  return <Box onDrop={() => updateIsWindowDragActive(false)}>{children}</Box>;
};

export default FullDropzone;
